import React from "react";
import TelegraIcon from "../assets/icons/ic_baseline-telegram.svg";
import InstaIcon from "../assets/icons/mdi_instagram.svg";
import LinkedIcon from "../assets/icons/mdi_linkedin.svg";
import BehanceIcon from "../assets/icons/ri_behance-fill.svg";
import GitHubIcon from "../assets/icons/mdi_github.svg";
import CVIcon from "../assets/icons/Pasted-20231224-204836 1.svg";

function Footer() {
  return (
    <footer>
      <div>
        <div className="gap-[48px]">
          <div className="flex flex-col gap-[16px]">
            <h1>Ready to transform your business?</h1>
            <p>
              Shoot me a note with your project details at{" "}
              <a className="underline" href="mailto:yakubov.amal321@gmail.com">
                yakubov.amal321@gmail.com
              </a>
            </p>
          </div>
          <a href="mailto:yakubov.amal321@gmail.com" className="button mr-auto">
            Send mail
          </a>
        </div>
        <div className="gap-[18px]">
          <h2>You can also find me there:</h2>
          <div className="container desktop:max-w-[294px]">
            <div className="media-list">
              <a className="media" target="_blank" href="https://t.me/amalkkaa">
                <img src={TelegraIcon} alt="telegram" />
                <p>Telegram</p>
              </a>

              <a
                className="media"
                target="_blank"
                href="https://www.instagram.com/amalkkaa/"
              >
                <img src={InstaIcon} alt="instagram" />
                <p>Instagram</p>
              </a>

              <a
                className="media"
                target="_blank"
                href="https://www.linkedin.com/in/amalkkaa/"
              >
                <img src={LinkedIcon} alt="linkedin" />
                <p>Linkedin</p>
              </a>
            </div>
            <div className="media-list">
              <a
                className="media"
                target="_blank"
                href="https://www.behance.net/amalkkaa/"
              >
                <img src={BehanceIcon} alt="behance" />
                <p>Behance</p>
              </a>

              <a
                className="media"
                target="_blank"
                href="https://github.com/blackkyy"
              >
                <img src={GitHubIcon} alt="github" />
                <p>GitHub</p>
              </a>

              <a
                className="media"
                target="_blank"
                href="https://read.cv/amalkkaa"
              >
                <img src={CVIcon} alt="CV" />
                <p>Read.cv</p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="line" />

      <div className="container text-grey-500 dark:text-grey-600">
        <h5>© 2023. Amal Yakubov</h5>
        <h5>
          <a target="_blank" href="https://symmetry.uz/">
            Developed by Symmetry
          </a>
        </h5>
      </div>
    </footer>
  );
}

export default Footer;
