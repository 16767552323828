import React from "react";

function Profile() {
  return (
    <div className="container flex-col gap-[8px]">
      <h3 className="text-grey-500">Profile</h3>
      <p>
        Howdy! I’m Amal, living & working in Tashkent, Uzb. An energetic
        and driven problem solver, UX-UI / Product Designer with experience in
        developing digital design solutions for Web and Mobile applications.
        <br />
        <br />
        My passion is creating helpful human-centered products with a motto
        “Made By People For People”. So far, I have designed products used by
        2,000,000 real people in Uzbekistan and Russia.
        <br />
        <br />
        Latest portfolio of work is available upon request.
      </p>
    </div>
  );
}

export default Profile;
