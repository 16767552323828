import React from "react";

function Case({ info }) {
  return (
    <a className="case" href={info.link} target="_blank">
      <img src={info.img} alt="idea" />
      <h2>{info.name}</h2>
    </a>
  );
}

export default Case;
