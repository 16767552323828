import React from 'react'
import Info from '../components/Info'
import Profile from '../components/Profile'
import Portfolio from '../components/Portfolio'

function Main() {
  const darkMode = (localStorage.getItem('darkMode') === 'true')
  if(darkMode){
    document.querySelector('html').classList.add('dark')
  }
  return (
    <section>
        <Info/>
        <Profile/>
        <Portfolio/>
    </section>
  )
}

export default Main