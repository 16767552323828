import React, { useState, useEffect } from 'react';

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const options = {
    timeZone: 'Asia/Tashkent',
    hour: '2-digit',
    minute: 'numeric',
    hour12: true,
  };

  const formattedTime = new Intl.DateTimeFormat('en-US', options).format(currentTime);

  return (
    <p className='my-auto'>Tashkent, {formattedTime.toLocaleLowerCase()}</p>
  );
};

export default Clock;
