import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFound";

function App() {

  return (
      <Router>
        <Header/>
        <Routes>
          <Route path="/" Component={Main}/>
          <Route path="*" Component={NotFound}/>
        </Routes>
        <Footer/>
      </Router>

  );
}

export default App;
