import React from 'react'
import Photo from '../assets/img/Ellipse 1.png'

function Info() {
  return (
    <div className='container'>
        <div className='flex mr-auto gap-[20px] desktop:gap-[24px]'>
            <a href='/'>
              <img src={Photo} alt='profile picture'/>
            </a>
            <div className='my-auto gap-[4px]'>
                <h2>Amal Yakubov</h2>
                <h2>UX/UI Designer @ <a target='_blank' href='https://ventionteams.com/' className=' dark:text-white underline'>Vention</a></h2>
            </div>
        </div>

    </div>
  )
}

export default Info