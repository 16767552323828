import React from 'react'
import Info from '../components/Info'
import Cosmos from '../assets/img/Pasted-20240107-131613 1.svg'
import Arrow from '../assets/icons/arrow-narrow-left (1).svg'

function NotFound() {
    const darkMode = (localStorage.getItem('darkMode') === 'true')
    if(darkMode){
      document.querySelector('html').classList.add('dark')
    }
  return (
    <section>
        <Info/>
        <div className='flex flex-col gap-[32px]'>
            <img src={Cosmos} alt='not found' className='cosmos'/>

            <div className='flex flex-col mx-auto gap-[12px]'>
                <h2 className='mx-auto'>Looks like you’re lost</h2>
                <p className='text-grey-400'>The page you’re looking for is not available</p>
            </div>

            <a href='/' className='button page'>
                <img src={Arrow} alt='arrow' className='arrow'/>
                <h2>Go to Home</h2>
            </a>
        </div>
    </section>
  )
}

export default NotFound