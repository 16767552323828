import React from "react";
import Case from "./Case";
import Img1 from "../assets/img/idea.jpg";
import Img2 from "../assets/img/terrapro.jpg";
import Img3 from "../assets/img/emirates.jpg";

const cases = [
  {
    name: "Website for idea.uz - the largest retailer of household appliances in Uzbekistan",
    img: Img1,
    link: "https://idea.uz/",
  },
  {
    name: "Web Design for the most popular youth clothing brand called Terrapro",
    img: Img2,
    link: "https://terrapro.uz/",
  },
  {
    name: "Emirates Fishing Tour - top travel agency in the UAE",
    img: Img3,
    link: "https://www.behance.net/gallery/182181243/Emirates-Fishing-Tour-Website-redesign",
  },
];

function Portfolio() {
  return (
    <div className="container flex-col gap-[40px]">
      {cases.map((info) => (
        <Case info={info} key={info.name} />
      ))}
    </div>
  );
}

export default Portfolio;
