import React from 'react'
import {useState,useEffect} from 'react'
import Clock from './Clock'

const body = document.querySelector('html')
console.log(body.classList)

function Header() {
    const darkMode = (localStorage.getItem('darkMode') === 'true')
    const [theme, setTheme] = useState(darkMode)
    const HandleTheme = () => {
        setTheme(!theme)
    }
    useEffect(() => {
        if(theme){
            localStorage.setItem('darkMode',theme)
        } else {
            localStorage.removeItem('darkMode')
        }
        body.classList.toggle('dark')
    },[theme])
  return (
    <header>
        <div className='container'>
            <Clock/>
            <div onClick={() => HandleTheme()} className='toggle'>
                {theme ? 'Lights off':'Lights on'}
            </div> 
        </div>

        <div className='line'/>
    </header>
  )
}

export default Header